import styled from 'styled-components';

import { TableCell } from '@material-ui/core';

const TableCellStyled = styled(TableCell)`
  // text-align: center;
  word-wrap: break-word;
  //overflow: hidden;
  //text-overflow: ellipsis;
`;

export default TableCellStyled;
