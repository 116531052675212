import { Button, Card, CardContent, Typography } from '@material-ui/core';

import React from 'react';

import { Grid } from '@material-ui/core';

import { TViagem } from '..';

import { useHistory } from 'react-router';

import Detalhe from './Detalhe';
import { TOnLoad } from '../..';

interface IDetalhesProps {
  location?: {
    state: {
      viagem: TViagem;
      filtros: TOnLoad;
    };
  };
}

const Detalhes: React.FC<IDetalhesProps> = ({ location }) => {
  const { viagem, filtros } = location?.state || {};
  const history = useHistory();

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={10}>
            <Grid container>
              <Grid item sm={6}>
                <Typography variant="h4" color="textSecondary">
                  Detalhes
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => history.push('/viagens', { filtros })}
                    >
                      Voltar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} alignItems="center" justify="center">
            <Detalhe viagem={viagem?.id} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Detalhes;
