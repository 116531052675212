import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const Reducer = (reducers: any) => {
  const persistedReducer = persistReducer(
    {
      key: '@g2w',
      storage,
      whitelist: ['auth', 'user'],
    },
    reducers,
  );

  return persistedReducer;
};

export default Reducer;
