import { format } from 'date-fns/esm';
import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';

interface IEventoProps {
  evento: TEvento;
}
export type TEvento = {
  created: string;
  eventName: string;
  isSuccess: boolean;
  transactionId: string;
  shortAnswer: string;
  completeAnswer: string;
};

const Evento: React.FC<IEventoProps> = ({ evento }) => {
  return (
    <TableRowStyled>
      <TableCellStyled>
        {format(new Date(evento.created), 'dd-MM-yyyy HH:mm')}
      </TableCellStyled>
      <TableCellStyled>{evento.eventName}</TableCellStyled>
      <TableCellStyled colSpan={2}>{evento.transactionId}</TableCellStyled>
      <TableCellStyled colSpan={2}>{evento.shortAnswer}</TableCellStyled>
      <TableCellStyled colSpan={2}>{evento.completeAnswer}</TableCellStyled>
    </TableRowStyled>
  );
};

export default Evento;
