import React, { useEffect } from 'react';

import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import {
  AvatarStyled,
  Wrapper,
  Title,
  SubTitle,
  Separetor,
  Row,
} from './styles';
import {
  Drawer as Material,
  AppBar,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { HomeOutlined, ExitToAppOutlined } from '@material-ui/icons';

import { menus } from './menus';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Logo from '../../assets/logo.png';
import { searchToken, logOut } from '../../store/modules/auth/actions';
import { store } from '../../store';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

const Drawer: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { profile } = store.getState().user;

  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let serachTokenTimer: NodeJS.Timeout | null = null;

  useEffect(() => {
    handleSearchToken();
  }, []);

  const handleSearchToken = () => {
    if (serachTokenTimer) {
      clearInterval(serachTokenTimer);
    }
    dispatch(searchToken());
    serachTokenTimer = setInterval(() => {
      handleSearchToken();
    }, 60000);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="Logo"
            style={{ maxHeight: '40px', cursor: 'pointer' }}
            onClick={() => history.push('/home')}
            src={Logo}
          />
          <Grid container justify="flex-end" alignItems="flex-end">
            <Grid item xs={4}>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={5}>
                  <Row>
                    <Separetor />
                    <Wrapper onClick={() => history.push('/profile')}>
                      <Title>{profile.name}</Title>
                      <SubTitle>{profile.companyName}</SubTitle>
                    </Wrapper>
                  </Row>
                </Grid>
                <Grid item xs={2}>
                  <Row>
                    <AvatarStyled onClick={() => history.push('/profile')}>
                      {profile.name[0]}
                    </AvatarStyled>
                  </Row>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Material
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => history.push('/home')}>
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText primary="Início" />
          </ListItem>
        </List>
        <Divider />
        <List>
          {menus.map((menu, i) => (
            <ListItem button key={i} onClick={() => history.push(menu.path)}>
              <ListItemIcon>
                <menu.icon />
              </ListItemIcon>
              <ListItemText primary={menu.name} />
            </ListItem>
          ))}
        </List>

        <List style={{ marginTop: 'auto' }}>
          <Divider />
          <ListItem button onClick={() => dispatch(logOut())}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </Material>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Drawer;
