import React, { useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {} from '@material-ui/core';
import { Card, CardContent, CircularProgress } from '@material-ui/core';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { TDetalhe } from './types';
import {
  AirportShuttle,
  Schedule,
  SwapHoriz,
  AirlineSeatReclineExtra,
} from '@material-ui/icons';
import { format } from 'date-fns';
import Passagens from './Passagens';
import Geolocalizacoes from './Geolocalizacoes';
import Paradas from './Paradas';

const Detalhe: React.FC<{ viagem?: number }> = ({ viagem = 0 }) => {
  const [detalhe, setDetalhe] = useState<TDetalhe>();
  const [loading, setLoading] = useState(true);
  const onLoad = async () => {
    try {
      const { data } = await api.get(`/api/triip/detail?triipId=${viagem}`);
      setDetalhe(data);
      setLoading(!data);
    } catch (error) {
      setLoading(false);
      toast.error('Erro tente novamente mais tarde!');
    }
  };

  useEffect(() => {
    onLoad();
  }, []);
  const eventos = detalhe?.triipEvents || [];
  const drivers = detalhe?.drivers || [];

  if (loading) {
    return <CircularProgress disableShrink />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Viagem</Typography>
            <div>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <Schedule color="secondary" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Início"
                    secondary={
                      (eventos.length > 0 &&
                        format(
                          new Date(eventos[0].dataHoraEvento as string),
                          'dd/MM/yyyy HH:mm',
                        )) ||
                      'NÃO INFORMADO'
                    }
                  />
                  <ListItemText
                    primary="Fim"
                    secondary={
                      (eventos.length > 1 &&
                        format(
                          new Date(eventos[1].dataHoraEvento as string),
                          'dd/MM/yyyy HH:mm',
                        )) ||
                      'NÃO INFORMADO'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SwapHoriz color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Tipo"
                    secondary={detalhe?.triipResource?.direction}
                  />
                </ListItem>
              </List>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Motoristas</Typography>
            <div>
              <List dense>
                {drivers.length > 0 ? (
                  drivers?.map(driver => (
                    <ListItem>
                      <ListItemIcon>
                        <AirlineSeatReclineExtra color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Nome" secondary={driver?.name} />
                      <ListItemText
                        primary="Início"
                        secondary={driver?.strStartedAt || 'NÃO INFORMADO'}
                      />
                      <ListItemText
                        primary="Fim"
                        secondary={driver?.strEndedAt || 'NÃO INFORMADO'}
                      />
                    </ListItem>
                  ))
                ) : (
                  <ListItemText primary="Registro não informado!!" />
                )}
              </List>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Veículo</Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <AirportShuttle color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary="VEÍCULO"
                  secondary={detalhe?.vehicle?.name}
                />
                <ListItemText
                  primary="PLACA"
                  secondary={detalhe?.vehicle?.plate}
                />
                <ListItemText
                  primary="CHASSI"
                  secondary={detalhe?.vehicle?.chassis || 'NÃO INFORMADO'}
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Geolocalizacoes
          speedTimeLocations={detalhe?.speedTimeLocations || []}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Passagens tickets={detalhe?.tickets || []} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Paradas stopDetectorResources={detalhe?.stopDetectorResource || []} />
      </Grid>
    </Grid>
  );
};

export default Detalhe;
