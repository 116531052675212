import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import Veiculos from '../pages/Veiculos';
import VeiculoCadastro from '../pages/Veiculos/Cadastro';
import Ocorrencias from '../pages/Ocorrencias';
import CadastroOcorrencia from '../pages/Ocorrencias/Cadastro';
import Usuarios from '../pages/Usuarios';
import CadastroUsuario from '../pages/Usuarios/Cadastro';
import Profile from '../pages/Profile';
import CadastroMotorista from '../pages/Motoristas/Cadastro';
import Motoristas from '../pages/Motoristas';
import Eventos from '../pages/Eventos';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import { Typography } from '@material-ui/core';
import Viagens from '../pages/Viagens';
import Detalhes from '../pages/Viagens/Viagem/Detalhes';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/home" exact component={Home} isPrivate />
      <Route path="/profile" exact component={Profile} isPrivate />
      <Route path="/veiculos" exact component={Veiculos} isPrivate />
      <Route
        path="/veiculo/cadastro"
        exact
        component={VeiculoCadastro}
        isPrivate
      />
      <Route path="/ocorrencias" exact component={Ocorrencias} isPrivate />
      <Route
        path="/ocorrencia/cadastro"
        exact
        component={CadastroOcorrencia}
        isPrivate
      />
      <Route path="/eventos" exact component={Eventos} isPrivate />

      <Route path="/usuarios" exact component={Usuarios} isPrivate />
      <Route
        path="/usuario/cadastro"
        exact
        component={CadastroUsuario}
        isPrivate
      />
      <Route path="/motoristas" exact component={Motoristas} isPrivate />
      <Route
        path="/motorista/cadastro"
        exact
        component={CadastroMotorista}
        isPrivate
      />
      <Route path="/viagens" exact component={Viagens} isPrivate />
      <Route path="/viagem/detalhes" exact component={Detalhes} isPrivate />
      <Route
        path="/construcao"
        exact
        isPrivate
        component={() => (
          <Typography>
            Tela em construção <AvTimerOutlinedIcon />
          </Typography>
        )}
      />
      <Route path="/" component={() => <h1>404 - Not Found</h1>} />
    </Switch>
  );
};

export default Routes;
