import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { cadastroOcorrenciaSuccess } from './actions';
import history from '../../../services/history';
import { TData } from '../../../pages/Ocorrencias/Cadastro';

interface ICadastroOcorrenciaProps {
  payload: {
    data: TData;
  };
}

export function* cadastroOcorrencia({ payload }: ICadastroOcorrenciaProps) {
  try {
    yield call(api.post, '/api/occurrence', payload.data);
    yield put(cadastroOcorrenciaSuccess());
    toast.success('Ocorrência cadastrada com sucesso!');
    history.push('/ocorrencias');
  } catch (err) {
    toast.error('Erro ao cadastrar ocorrência, tente novamente mais tarde!');
  }
}

export default all([
  takeLatest<string, any>(
    '@ocorrencia/CADASTRO_OCORRENCIA_REQUEST',
    cadastroOcorrencia,
  ),
]);
