import React from 'react';

import { CardContent, Card, Grid } from '@material-ui/core';
import CardIcon from '../../components/CardIcon';
import { menus, novidades } from '../../layout/Drawer/menus';
import { ContainerStyled } from './styles';
import { Alert } from '@material-ui/lab';

const Home: React.FC = () => {
  return (
    <ContainerStyled>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Alert severity="success">Novidades do Triip Monitor</Alert>
              <Grid container>
                {menus.map(menu => (
                  <CardIcon
                    color="primary"
                    key={menu.name}
                    Icon={menu.icon}
                    path={menu.path}
                    name={menu.name}
                  />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Alert severity="info">Em breve</Alert>
              <Grid container>
                {novidades.map(novidade => (
                  <CardIcon
                    color="secondary"
                    disabled
                    key={novidade.name}
                    Icon={novidade.icon}
                    path={novidade.path}
                    name={novidade.name}
                  />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
};

export default Home;
