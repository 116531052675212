import produce from 'immer';
import { Action } from 'redux';

export type TPrivilege = { id: number; key: string };

export type TUser = {
  id: number;
  companyId: number;
  name: string;
  email: string;
  privileges: TPrivilege[];
};
interface IAction extends Action {
  payload: {
    userResource: TUser | null;
  };
}

const INITIAL_STATE = {
  profile: null,
  loading: false,
};

export interface AuthStateProps {
  readonly profile: TUser | null;
  readonly loading: boolean;
}

export default function user(state = INITIAL_STATE, action: IAction) {
  return produce<AuthStateProps>(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.userResource;
        break;
      }

      case '@user/UPDATE_PROFILE_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.loading = false;
        draft.profile = action.payload.userResource;
        break;
      }

      case '@user/UPDATE_PASSWORD_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/UPDATE_PASSWORD_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }

      default:
    }
  });
}
