import { combineReducers } from 'redux';
import auth from './auth/reducer';
import user from './user/reducer';
import ocorrencia from './ocorrencia/reducer';
import usuario from './usuario/reducer';
import veiculo from './veiculo/reducer';

export default combineReducers({
  auth,
  user,
  ocorrencia,
  usuario,
  veiculo,
});
