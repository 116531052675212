import { TData } from '../../../pages/Ocorrencias/Cadastro';
export function cadastroOcorrenciaRequest(data: TData) {
  return {
    type: '@ocorrencia/CADASTRO_OCORRENCIA_REQUEST',
    payload: { data },
  };
}

export function cadastroOcorrenciaSuccess() {
  return {
    type: '@ocorrencia/CADASTRO_OCORRENCIA_SUCCESS',
  };
}
