import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';
import { MoreVertOutlined, Room } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import { IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router';
import { TOnLoad } from '../';
import { useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { TSpeedTimeLocation } from './Detalhes/Detalhe/types';

interface IViagemProps {
  viagem: TViagem;
  filtros?: TOnLoad;
}
export type TViagem = {
  direction: boolean;
  driverName: string;
  id: number;
  lastUpdate: string;
  prefix: string;
  status: true;
  triipDate: string;
  vehicleName: string;
  totalCancels: number;
  totalOnboarding: number;
  totalTickets: number;
};

const Viagem: React.FC<IViagemProps> = ({ viagem, filtros }) => {
  const [state, setState] = useState<TSpeedTimeLocation[] | undefined>();
  const history = useHistory();
  const length = state?.length;
  const location = state && length ? state[length - 1] : undefined;
  useEffect(() => {
    const onLoad = async () => {
      try {
        const {
          data: { speedTimeLocations },
        } = await api.get(`/api/triip/detail?triipId=${viagem.id}`);
        setState(speedTimeLocations);
      } catch (error) {
        toast.error('Erro tente novamente mais tarde!');
      }
    };
    onLoad();
  }, []);

  return (
    <TableRowStyled>
      <TableCellStyled>{viagem.triipDate}</TableCellStyled>
      <TableCellStyled>{viagem.prefix}</TableCellStyled>
      <TableCellStyled>{viagem.direction}</TableCellStyled>
      <TableCellStyled>{viagem.lastUpdate}</TableCellStyled>
      <TableCellStyled>{viagem.totalTickets}</TableCellStyled>
      <TableCellStyled>{viagem.totalCancels}</TableCellStyled>
      <TableCellStyled>{viagem.totalOnboarding}</TableCellStyled>
      <TableCellStyled>
        {viagem.status ? 'FINALIZADO' : 'EM ANDAMENTO'}
      </TableCellStyled>
      <TableCellStyled>
        <Tooltip title="Visualizar" placement="top-end">
          <IconButton
            onClick={() =>
              history.push({
                pathname: '/viagem/detalhes',
                state: { viagem, filtros },
              })
            }
          >
            <MoreVertOutlined />
          </IconButton>
        </Tooltip>
      </TableCellStyled>
      <TableCellStyled>
        <Link
          href={`http://maps.google.com/maps?z=12&t=m&q=loc:${
            location && location.latitude
          },${location && location.longitude}`}
        >
          <Tooltip title="Rastrear" placement="top-end">
            <IconButton>
              <Room />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export default Viagem;
