import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Veiculo, { TVeiculo } from './Veiculo';
import api from '../../services/api';
import { toast } from 'react-toastify';
import TitleGrid from '../../components/TitleGrid';

const Veiculos: React.FC = () => {
  const [veiculo, setVeiculo] = useState<TVeiculo[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const onLoad = async () => {
    try {
      setLoading(true);
      const {
        data: { content, totalPages },
      } = await api.get(`/api/vehicle?page=${page - 1}&size=10`);
      setVeiculo(content);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar veículos, tente novamente mais tarde.');
      setLoading(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    onLoad();
  }, [page]);

  return (
    <Container>
      <Card>
        <CardContent>
          <TitleGrid title="Veículos" path="/veiculo/cadastro" />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled />
                      <TableCellStyled>Nome</TableCellStyled>
                      <TableCellStyled>Placa</TableCellStyled>
                      <TableCellStyled colSpan={2}>Chassi</TableCellStyled>
                      <TableCellStyled />
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {loading ? (
                      <CircularProgress disableShrink />
                    ) : (
                      veiculo.map((veiculo, i) => (
                        <Veiculo key={veiculo.id} veiculo={veiculo} index={i} />
                      ))
                    )}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                count={totalPages}
                color="primary"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Veiculos;
