import styled from 'styled-components';
import { Avatar } from '@material-ui/core';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Separetor = styled.div`
  width: 1px;
  height: 50px;
  margin-right: 10px;
  background-color: #fff;
`;

export const AvatarStyled = styled(Avatar)`
  color: rgb(88 184 99);
  background-color: #fff;
`;
export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;
export const SubTitle = styled.span`
  font-size: 12px;
  color: #fff;
  opacity: 0.9;
`;
