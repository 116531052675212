import { TData } from '../../../pages/Usuarios/Cadastro';
export function cadastroUsuarioRequest(data: TData) {
  return {
    type: '@usuario/CADASTRO_USUARIO_REQUEST',
    payload: { data },
  };
}

export function cadastroUsuarioSuccess() {
  return {
    type: '@usuario/CADASTRO_USUARIO_SUCCESS',
  };
}
