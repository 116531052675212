import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Ocorrencia, { TOcorrencia } from './Ocorrencia';
import api from '../../services/api';
import { toast } from 'react-toastify';
import TitleGrid from '../../components/TitleGrid';

const Ocorrencias: React.FC = () => {
  const [ocorrencias, setOcorrencias] = useState<TOcorrencia[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const onLoad = async () => {
    try {
      const {
        data: { content, totalPages },
      } = await api.get(`/api/occurrence?page=${page - 1}&size=10`);
      setOcorrencias(content);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error('Erro intervalo entre datas maior que 30 dias');
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    onLoad();
  }, [page]);

  return (
    <Container>
      <Card>
        <CardContent>
          <TitleGrid title="Ocorrências" path="/ocorrencia/cadastro" />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled>Data da Reclamação</TableCellStyled>
                      <TableCellStyled>Numero do protocolo</TableCellStyled>
                      <TableCellStyled>Nome do reclamante</TableCellStyled>
                      <TableCellStyled colSpan={2}>
                        Email do reclamante
                      </TableCellStyled>
                      <TableCellStyled>Telefone do reclamante</TableCellStyled>
                      <TableCellStyled />
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {ocorrencias.map((ocorrencia, i) => (
                      <Ocorrencia
                        key={ocorrencia.id}
                        ocorrencia={ocorrencia}
                        setOcorrencias={setOcorrencias}
                        index={i}
                      />
                    ))}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                onChange={handleChange}
                count={totalPages}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Ocorrencias;
