import { Container, Typography } from '@material-ui/core';

import styled from 'styled-components';

export const ContainerStyled = styled(Container)`
  width: 100%;
`;
export const TypographyStyled = styled(Typography)`
  text-align: center;
`;
