import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { cadastroUsuarioSuccess } from './actions';
import history from '../../../services/history';
import { TData } from '../../../pages/Usuarios/Cadastro';

interface ICadastroUsuarioProps {
  payload: {
    data: TData;
  };
}

export function* cadastroUsuario({ payload }: ICadastroUsuarioProps) {
  try {
    yield call(api.post, '/api/user', payload.data);
    yield put(cadastroUsuarioSuccess());
    toast.success('Usuário cadastrado com sucesso!');
    history.push('/usuarios');
  } catch (err) {
    toast.error('Erro ao cadastrar usuário, tente novamente mais tarde!');
  }
}

export default all([
  takeLatest<string, any>('@usuario/CADASTRO_USUARIO_REQUEST', cadastroUsuario),
]);
