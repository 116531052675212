import { useHistory } from 'react-router';

import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledOutlined from '@material-ui/icons/PlayCircleFilledOutlined';

import api from '../../../services/api';
import { toast } from 'react-toastify';
import { EditOutlined } from '@material-ui/icons';
interface IUsuarioProps {
  usuario: TUsuario;
  index: number;
  setUsuarios: Function;
}
export type TUsuario = {
  id: number;
  companyId: number;
  name: string;
  email: string;
  createdAt: string;
  removedAt: null | string;
};

const Usuario: React.FC<IUsuarioProps> = ({ usuario, index, setUsuarios }) => {
  const history = useHistory();

  const handleDisable = async () => {
    try {
      const {
        data: { removedAt },
      } = await api.put(`/api/user/disable/${usuario.id}`);
      updateStatus(removedAt);
      toast.success('Usuário desativado com sucesso!');
    } catch (err) {
      toast.error('Erro ao desativar usuário, tente novamente mais tarde!');
    }
  };

  const handleEnable = async () => {
    try {
      const {
        data: { removedAt },
      } = await api.put(`/api/user/enable/${usuario.id}`);
      updateStatus(removedAt);
      toast.success('Usuário ativado com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar usuário, tente novamente mais tarde!');
    }
  };

  const updateStatus = (removedAt: null | string) => {
    setUsuarios((prevState: TUsuario[]) => {
      const newState = [...prevState];
      newState[index].removedAt = removedAt;
      return newState;
    });
  };

  return (
    <TableRowStyled>
      <TableCellStyled colSpan={2}>{usuario.name}</TableCellStyled>
      <TableCellStyled colSpan={2}>{usuario.email}</TableCellStyled>
      <TableCellStyled>
        <Grid container>
          <Grid item md={6}>
            <Tooltip title="Editar" placement="top-end">
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: '/usuario/cadastro',
                    state: { usuario },
                  })
                }
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={6}>
            {!usuario.removedAt ? (
              <Tooltip title="Desativar" placement="top-end">
                <IconButton onClick={handleDisable}>
                  <PauseCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Ativar" placement="top-end">
                <IconButton onClick={handleEnable}>
                  <PlayCircleFilledOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export default Usuario;
