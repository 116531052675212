import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
* {
margin: 0;
padding: 0;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
list-style-type: none;
text-decoration: none;
}
html
body {
border: 0;
font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
width: 100%;
height: 100%;
overflow: auto;


input {
  border: 1px solid #ddd;
    width: 250px;
    padding: 7px 0;
  }

}

body{
  background-color: #F3F3F3;
}

`;
