import { TData } from '../../../pages/Profile';
import { TUser } from './reducer';

export function updateProfileRequest(data: TData) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}

export function updateProfileSuccess(userResource: TUser) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { userResource },
  };
}

interface IUpdatePasswordProps {
  id: number;
  password: string;
  newPassword: string;
}

export function updatePasswordRequest(data: IUpdatePasswordProps) {
  return {
    type: '@user/UPDATE_PASSWORD_REQUEST',
    payload: { data },
  };
}
export function updatePasswordSuccess() {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
  };
}
