import { TData } from '../../../pages/Veiculos/Cadastro';
export function cadastroVeiculoRequest(data: TData) {
  return {
    type: '@veiculo/CADASTRO_VEICULO_REQUEST',
    payload: { data },
  };
}

export function cadastroVeiculoSuccess() {
  return {
    type: '@veiculo/CADASTRO_VEICULO_SUCCESS',
  };
}

export function updateVeiculoRequest(data: any) {
  return {
    type: '@veiculo/UPDATE_VEICULO_REQUEST',
    payload: { data },
  };
}

export function updateVeiculoSuccess() {
  return {
    type: '@veiculo/UPDATE_VEICULO_SUCCESS',
  };
}
