import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Usuario, { TUsuario } from './Usuario';
import api from '../../services/api';

import { toast } from 'react-toastify';
import TitleGrid from '../../components/TitleGrid';

const Usuarios: React.FC = () => {
  const [usuarios, setUsuarios] = useState<TUsuario[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    try {
      setLoading(true);
      const {
        data: { content, totalPages },
      } = await api.get(`/api/user?page=${page - 1}&size=10`);
      setUsuarios(content);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      toast.error('Erro intervalo entre datas maior que 30 dias');
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    onLoad();
  }, [page]);

  return (
    <Container>
      <Card>
        <CardContent>
          <TitleGrid title="Usuários" path="/usuario/cadastro" />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled colSpan={2}>Nome</TableCellStyled>
                      <TableCellStyled colSpan={2}>Email</TableCellStyled>
                      <TableCellStyled />
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {loading ? (
                      <CircularProgress disableShrink />
                    ) : (
                      usuarios.map((usuario, i) => (
                        <Usuario
                          key={usuario.id}
                          usuario={usuario}
                          index={i}
                          setUsuarios={setUsuarios}
                        />
                      ))
                    )}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                onChange={handleChange}
                count={totalPages}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Usuarios;
