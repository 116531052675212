import { Card, CardContent, Container } from '@material-ui/core';

import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import { Grid, Button, Typography } from '@material-ui/core';

import { Form, Input } from '@yagolopes/unityform';

import { schema } from './schema';
import { store } from '../../store';
import { updateProfileRequest } from '../../store/modules/user/actions';

import { useDispatch } from 'react-redux';

export type TData = {
  id: number;
  name: string;
  email: string;
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const Profile: React.FC = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { profile } = store.getState().user;
  const dispatch = useDispatch();

  const handleSubmit = async (data: TData) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      if (data.newPassword !== data.newPasswordConfirm) {
        setErrors({
          newPasswordConfirm: 'Confirmação está diferente da nova senha!!',
        });
        return;
      }
      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(updateProfileRequest({ ...data, id: profile.id }));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          console.log(error.path);
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Form initialData={profile} ref={formRef} onSubmit={handleSubmit}>
                <Typography variant="h4" color="textSecondary">
                  Minha conta
                </Typography>
                <Grid
                  style={{ marginTop: 20, marginBottom: 20 }}
                  container
                  spacing={2}
                >
                  <Input
                    title="Nome"
                    name="name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <Input
                    title="Email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <Input
                    title="Senha atual"
                    name="password"
                    variant="outlined"
                    required
                    fullWidth
                    type="password"
                  />
                </Grid>
                <Typography variant="h6" color="textSecondary">
                  Alterar senha
                </Typography>
                <Grid
                  style={{ marginTop: 20, marginBottom: 20 }}
                  container
                  spacing={2}
                >
                  <Input
                    title="Nova senha"
                    name="newPassword"
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                  <Input
                    title="Confirmação da nova senha"
                    name="newPasswordConfirm"
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                </Grid>

                <Grid
                  style={{ marginTop: 50 }}
                  container
                  spacing={2}
                  justify="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => history.push('/home')}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Profile;
