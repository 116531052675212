import { TUser } from '../user/reducer';
export type SignInRequestProps = {
  username: string;
  password: string;
};

export function signInRequest({ username, password }: SignInRequestProps) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { username, password },
  };
}

export function signInSuccess(
  token: string,
  expiresAt: Date,
  expiresSec: number,
  userResource: TUser,
) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, expiresAt, expiresSec, userResource },
  };
}
export function refreshToken() {
  return {
    type: '@auth/REFRESH_TOKEN',
  };
}

export function refreshTokenSuccess(
  token: string,
  expiresAt: Date,
  expiresSec: number,
) {
  return {
    type: '@auth/REFRESH_TOKEN_SUCCESS',
    payload: { token, expiresAt, expiresSec },
  };
}

export function searchToken() {
  return {
    type: '@auth/SEARCH_TOKEN',
  };
}

export function searchTokenSuccess(
  token: string,
  expiresAt: Date,
  expiresSec: number,
) {
  return {
    type: '@auth/SEARCH_TOKEN_SUCCESS',
    payload: { token, expiresAt, expiresSec },
  };
}

export function signUpRequest(data: any) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { data },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function logOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
