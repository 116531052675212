import { useHistory } from 'react-router';

import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Edit, Delete } from '@material-ui/icons';

import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';

interface IVeiculoProps {
  veiculo: TVeiculo;
  index: number;
}
export type TVeiculo = {
  id: number;
  companyId: number | null;
  name: string;
  plate: string;
  chassis: string | null;
  createdAt: string;
  removedAt: string | null;
};

const Veiculo: React.FC<IVeiculoProps> = ({ veiculo, index }) => {
  const history = useHistory();

  return (
    <TableRowStyled>
      <TableCellStyled>{index + 1}</TableCellStyled>
      <TableCellStyled>{veiculo.name}</TableCellStyled>
      <TableCellStyled>{veiculo.plate}</TableCellStyled>
      <TableCellStyled colSpan={2}>{veiculo.chassis}</TableCellStyled>
      <TableCellStyled>
        <Grid container>
          <Grid item md={6}>
            <Tooltip title="Editar" placement="top-end">
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: '/veiculo/cadastro',
                    state: { veiculo },
                  })
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={6}>
            <Tooltip title="Excluir" placement="top-end">
              <IconButton onClick={() => {}}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export default Veiculo;
