import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {
  updateProfileSuccess,
  updatePasswordRequest,
  updatePasswordSuccess,
} from './actions';
import { TData } from '../../../pages/Profile';

interface IUpdateProfileProps {
  payload: {
    data: TData;
  };
}

export function* updateProfile({ payload }: IUpdateProfileProps) {
  try {
    const { id, name, email, password, newPassword } = payload.data;
    const response = yield call(api.put, '/api/user', {
      id,
      name,
      email,
      password,
    });
    yield put(updateProfileSuccess(response.data));
    if (newPassword) {
      yield put(updatePasswordRequest({ id, password, newPassword }));
    }
    toast.success('Perfil atualizado com sucesso!');
  } catch (err) {
    toast.error('Erro ao atualizar seu perfil, tente novamente mais tarde!');
  }
}

interface IUpdatePasswordProps {
  payload: {
    data: {
      password: string;
      newPassword: string;
      id: number;
    };
  };
}

export function* updatePassword({ payload }: IUpdatePasswordProps) {
  try {
    const { newPassword, password, id } = payload.data;
    yield call(api.put, `/api/user/${id}/reset-password`, {
      newPassword,
      password,
    });
    yield put(updatePasswordSuccess());
    toast.success('Senha alterada com sucesso!');
  } catch (err) {
    toast.error('Erro ao atualizar sua senha, tente novamente mais tarde!');
  }
}

export default all([
  takeLatest<string, any>('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest<string, any>('@user/UPDATE_PASSWORD_REQUEST', updatePassword),
]);
