import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import Public from '@material-ui/icons/Public';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../../../../../components/Table';
import { TSpeedTimeLocation } from '../types';

interface IGeolocalizacoesProps {
  speedTimeLocations?: TSpeedTimeLocation[];
}

const Geolocalizacoes: React.FC<IGeolocalizacoesProps> = ({
  speedTimeLocations = [],
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Geolocalização</Typography>
        <div style={{ overflowY: 'auto' }}>
          <TableStyled>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>Data</TableCellStyled>
                <TableCellStyled>Latitude</TableCellStyled>
                <TableCellStyled>Longitude</TableCellStyled>
                <TableCellStyled>Velocidade Atual</TableCellStyled>
                <TableCellStyled>Transmitido</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>
            <TableBodyStyled>
              {speedTimeLocations.map(location => (
                <TableRowStyled key={location.id}>
                  <TableCellStyled>
                    <Typography color="textSecondary">
                      <Public color="secondary" />
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled>
                    {location.strDataHoraEvento}
                  </TableCellStyled>
                  <TableCellStyled>{location.latitude}</TableCellStyled>
                  <TableCellStyled>{location.longitude}</TableCellStyled>
                  <TableCellStyled>{location.velocidadeAtual}</TableCellStyled>
                  <TableCellStyled>
                    {location.isTransmit ? 'SIM' : 'NÃO'}
                  </TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBodyStyled>
          </TableStyled>
        </div>
      </CardContent>
    </Card>
  );
};

export default Geolocalizacoes;
