import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Evento, { TEvento } from './Evento';
import api from '../../services/api';
import Filtro from './Filtro';
import { toast } from 'react-toastify';
import TitleGrid from '../../components/TitleGrid';
import { format } from 'date-fns';

type TOnLoad = {
  dateStart: string;
  dateEnd: string;
  eventId: number;
};

const Eventos: React.FC = () => {
  const dataAtual = new Date();
  const [eventos, setEventos] = useState<TEvento[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [tempFilter, setTempFilter] = useState<TOnLoad>({
    dateStart: format(dataAtual, 'yyyy-MM-dd'),
    dateEnd: format(dataAtual, 'yyyy-MM-dd'),
    eventId: 4,
  });

  const onLoad = async ({ dateStart, dateEnd, eventId }: TOnLoad) => {
    try {
      setTempFilter({ dateStart, dateEnd, eventId });
      const {
        data: { content, totalPages },
      } = await api.get(
        `/api/event?page=${
          page - 1
        }&size=50&dateStart=${dateStart}&dateEnded=${dateEnd}&eventId=${eventId}`,
      );
      setEventos(content);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error('Erro intervalo entre datas maior que 30 dias');
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    onLoad(tempFilter);
  }, [page]);

  return (
    <Container>
      <Filtro onSubmit={onLoad} />
      <Card>
        <CardContent>
          <TitleGrid title="Eventos" />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled>Data/Hora</TableCellStyled>
                      <TableCellStyled>Tipo</TableCellStyled>
                      <TableCellStyled colSpan={2}>
                        Transaction ID
                      </TableCellStyled>
                      <TableCellStyled colSpan={2}>Resposta</TableCellStyled>
                      <TableCellStyled colSpan={2}>
                        Resposta Completa
                      </TableCellStyled>
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {eventos.map(evento => (
                      <Evento key={evento.created} evento={evento} />
                    ))}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                onChange={handleChange}
                count={totalPages}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Eventos;
