import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import theme from './styles/theme';
import GlobalStyles from './styles/globalStyles';
import ThemeProvider from './styles/ThemeProvider';
import { Router } from 'react-router-dom';
import history from './services/history';

import { store, persistor } from './store';
import Routes from './routes';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastContainer autoClose={3000} />
          <Routes />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
