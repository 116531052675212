import { useHistory } from 'react-router';

import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledOutlined from '@material-ui/icons/PlayCircleFilledOutlined';

import {
  desativarMotoristaRequest,
  ativarMotoristaRequest,
} from '../../../store/modules/motorista/actions';
import { useDispatch } from 'react-redux';
interface IMotoristaProps {
  motorista: TMotorista;
}
export type TMotorista = {
  id: number;
  companyId: number;
  name: string;
  document: string;
  address: string;
  createdAt: string;
  removedAt: null | string;
};

const Motorista: React.FC<IMotoristaProps> = ({ motorista }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <TableRowStyled>
      <TableCellStyled>{motorista.name}</TableCellStyled>
      <TableCellStyled>{motorista.document}</TableCellStyled>
      <TableCellStyled colSpan={2}>{motorista.address}</TableCellStyled>
      <TableCellStyled>
        <Grid container>
          <Grid item md={6}>
            <Tooltip title="Visualizar" placement="top-end">
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: '/motorista/cadastro',
                    state: { motorista },
                  })
                }
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={6}>
            {!motorista.removedAt ? (
              <Tooltip title="Desativar" placement="top-end">
                <IconButton
                  onClick={() =>
                    dispatch(desativarMotoristaRequest(motorista.id))
                  }
                >
                  <PauseCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Ativar" placement="top-end">
                <IconButton
                  onClick={() => dispatch(ativarMotoristaRequest(motorista.id))}
                >
                  <PlayCircleFilledOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export default Motorista;
