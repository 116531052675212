import { Card } from '@material-ui/core';

import styled from 'styled-components';

export const Container = styled(Card)`
  display: flex;
  height: 100%;
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'none' : ' block'};
`;
export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #ddd;
  cursor: pointer;
`;

export const Asside = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
`;
