import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import {
  signInSuccess,
  signFailure,
  searchTokenSuccess,
  refreshToken as actionRefreshToken,
  refreshTokenSuccess,
  logOut,
} from './actions';
import { IAuthAction } from './reducer';
import { Action } from 'redux';

interface ISetTokenAction extends Action {
  payload: {
    auth: {
      token: string;
    };
  };
}

export function* signIn({ payload }: IAuthAction) {
  try {
    const { username, password } = payload;
    const response = yield call(api.post, '/auth/full', {
      username,
      password,
    });
    const { token, expiresAt, expiresSec, userResource } = response.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, expiresAt, expiresSec, userResource));
    history.push('/home');
  } catch (err) {
    yield put(signFailure());
    toast.error('Falha na autenticação, verifique seus dados');
  }
}

export function setToken({ payload }: ISetTokenAction) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* refreshToken() {
  try {
    const response = yield call(api.get, '/auth/refresh');
    const { token, expiresAt, expiresSec } = response.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(refreshTokenSuccess(token, expiresAt, expiresSec));
  } catch (err) {
    yield put(logOut());
    toast.error('Falha na autenticação, sua sessão expirou');
  }
}

export function* searchToken() {
  try {
    const response = yield call(api.get, '/auth/search-token');
    const { token, expiresAt, expiresSec } = response.data;
    if (expiresSec < 100) {
      yield put(actionRefreshToken());
    }
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(searchTokenSuccess(token, expiresAt, expiresSec));
  } catch (err) {
    yield put(logOut());
    toast.error('Falha na autenticação, sua sessão expirou');
  }
}

export function signOut() {
  api.defaults.headers.Authorization = undefined;
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/REFRESH_TOKEN', refreshToken),
  takeLatest('@auth/SEARCH_TOKEN', searchToken),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
