import { CardContent, Grid, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';

import { Container, CardContainer, Asside } from './styles';

interface ICardIconProps {
  name: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  path: string;
  disabled?: boolean;
  color?: 'disabled' | 'inherit' | 'action' | 'primary' | 'secondary' | 'error';
}

const CardIcon: React.FC<ICardIconProps> = ({
  name,
  Icon,
  path,
  disabled = false,
  color,
}) => {
  const history = useHistory();
  return (
    <Grid xs={6} sm={4} style={{ padding: 10 }}>
      <Container disabled={disabled}>
        <CardContainer onClick={() => history.push(path)}>
          <CardContent>
            <Typography color="textSecondary" component="h5" variant="h5">
              {name}
            </Typography>
          </CardContent>
        </CardContainer>
        <Asside>
          <Icon color={color} />
        </Asside>
      </Container>
    </Grid>
  );
};

export default CardIcon;
