import {
  DirectionsBusOutlined,
  GroupAddOutlined,
  AssignmentLateOutlined,
  TrackChangesOutlined,
  EventNoteOutlined,
  AirlineSeatReclineExtraOutlined,
  RoomOutlined,
} from '@material-ui/icons';

export const menus = [
  {
    name: 'Usuários',
    icon: GroupAddOutlined,
    path: '/usuarios',
  },
  {
    name: 'Eventos',
    icon: EventNoteOutlined,
    path: '/eventos',
  },

  {
    name: 'Ocorrências',
    icon: AssignmentLateOutlined,
    path: '/ocorrencias',
  },

  {
    name: 'Viagens',
    icon: RoomOutlined,
    path: '/viagens',
  },
  {
    name: 'Veículos',
    icon: DirectionsBusOutlined,
    path: '/veiculos',
  },
  {
    name: 'Motoristas',
    icon: AirlineSeatReclineExtraOutlined,
    path: '/motoristas',
  },
];
export const novidades = [
  {
    name: 'Rastrear Veículos',
    icon: TrackChangesOutlined,
    path: '/construcao',
  },
];
