import produce from 'immer';
import { Action } from 'redux';

const INITIAL_STATE = {
  token: null,
  expiresAt: null,
  expiresSec: null,
  signed: false,
  loading: false,
};

export interface IAuthAction extends Action {
  payload: {
    token: string;
    expiresAt: Date;
    expiresSec: number;
    username: string;
    password: string;
  };
}

export interface AuthStateProps {
  readonly token: string | null;
  readonly expiresAt: Date | null;
  readonly expiresSec: number | null;
  readonly signed: boolean;
  readonly loading: boolean;
}

export default function auth(state = INITIAL_STATE, action: IAuthAction) {
  return produce<AuthStateProps>(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.expiresAt = action.payload.expiresAt;
        draft.expiresSec = action.payload.expiresSec;
        draft.signed = true;
        draft.loading = false;
        break;
      }

      case '@auth/REFRESH_TOKEN_SUCCESS': {
        draft.token = action.payload.token;
        draft.expiresAt = action.payload.expiresAt;
        draft.expiresSec = action.payload.expiresSec;
        break;
      }

      case '@auth/SEARCH_TOKEN_SUCCESS': {
        draft.token = action.payload.token;
        draft.expiresAt = action.payload.expiresAt;
        draft.expiresSec = action.payload.expiresSec;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.expiresAt = null;
        draft.expiresSec = null;
        draft.signed = false;

        break;
      }
      default:
    }
  });
}
