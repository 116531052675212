
import { MuiThemeProvider, Theme } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider as StyledCompoents } from 'styled-components';


interface IThemeProviderProps  {
    theme: Theme
}

const ThemeProvider:React.FC<IThemeProviderProps> = ({ children, theme }) => {
 return (
  <StylesProvider injectFirst>
   <MuiThemeProvider theme={theme}>
    <StyledCompoents theme={theme}>{children}</StyledCompoents>
   </MuiThemeProvider>
  </StylesProvider>
 );
};

export default ThemeProvider;
