import { useHistory } from 'react-router';

import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import TableCellStyled from '../../../components/Table/TableCellStyled';
import TableRowStyled from '../../../components/Table/TableRowStyled';

import { MoreVertOutlined } from '@material-ui/icons';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledOutlined from '@material-ui/icons/PlayCircleFilledOutlined';

import api from '../../../services/api';
import { toast } from 'react-toastify';
interface IOcorrenciaProps {
  ocorrencia: TOcorrencia;
  setOcorrencias: Function;
  index: number;
}
export type TOcorrencia = {
  id: number;
  companyId: number;
  numeroBilheteEmbarque: number;
  nomeReclamante: string;
  documentoIdentificacaoReclamante: string;
  enderecoReclamante: string;
  telefoneContatoReclamante: string;
  emailReclamante: string;
  codigoTipoOcorrencia: number;
  dataOcorrencia: string;
  horaOcorrencia: string;
  descricaoOcorrencia: string;
  isTransmit: boolean;
  createdAt: string;
  removedAt: null | string;
};

const Ocorrencia: React.FC<IOcorrenciaProps> = ({
  ocorrencia,
  setOcorrencias,
  index,
}) => {
  const history = useHistory();

  const handleDisable = async () => {
    try {
      const {
        data: { removedAt },
      } = await api.put(`/api/occurrence/disable/${ocorrencia.id}`);
      updateStatus(removedAt);
      toast.success('Ocorrência desativada com sucesso!');
    } catch (err) {
      toast.error('Erro ao desativar ocorrência, tente novamente mais tarde!');
    }
  };

  const handleEnable = async () => {
    try {
      const {
        data: { removedAt },
      } = await api.put(`/api/occurrence/enable/${ocorrencia.id}`);
      updateStatus(removedAt);
      toast.success('Ocorrência ativada com sucesso!');
    } catch (err) {
      toast.error('Erro ao ativar ocorrência, tente novamente mais tarde!');
    }
  };

  const updateStatus = (removedAt: null | string) => {
    setOcorrencias((prevState: TOcorrencia[]) => {
      const newState = [...prevState];
      newState[index].removedAt = removedAt;
      return newState;
    });
  };

  return (
    <TableRowStyled>
      <TableCellStyled>{ocorrencia.dataOcorrencia}</TableCellStyled>
      <TableCellStyled>{ocorrencia.numeroBilheteEmbarque}</TableCellStyled>
      <TableCellStyled>{ocorrencia.nomeReclamante}</TableCellStyled>
      <TableCellStyled colSpan={2}>
        {ocorrencia.emailReclamante}
      </TableCellStyled>
      <TableCellStyled colSpan={2}>
        {ocorrencia.telefoneContatoReclamante}
      </TableCellStyled>
      <TableCellStyled>
        <Grid container>
          <Grid item md={6}>
            <Tooltip title="Visualizar" placement="top-end">
              <IconButton
                onClick={() =>
                  history.push({
                    pathname: '/ocorrencia/cadastro',
                    state: { ocorrencia },
                  })
                }
              >
                <MoreVertOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item md={6}>
            {!ocorrencia.removedAt ? (
              <Tooltip title="Desativar" placement="top-end">
                <IconButton onClick={handleDisable}>
                  <PauseCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Ativar" placement="top-end">
                <IconButton onClick={handleEnable}>
                  <PlayCircleFilledOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </TableCellStyled>
    </TableRowStyled>
  );
};

export default Ocorrencia;
