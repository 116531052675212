import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { PanTool } from '@material-ui/icons';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../../../../../components/Table';
import { TStopDetectorResource } from '../types';

interface IParadasProps {
  stopDetectorResources?: TStopDetectorResource[];
}

const Paradas: React.FC<IParadasProps> = ({ stopDetectorResources = [] }) => {
  if (stopDetectorResources.length > 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">Paradas</Typography>

          <TableStyled>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>Data/Hora</TableCellStyled>
                <TableCellStyled>Latitude</TableCellStyled>
                <TableCellStyled>Longitude</TableCellStyled>
                <TableCellStyled>Motivo</TableCellStyled>
                <TableCellStyled>Transmitido</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>
            <TableBodyStyled>
              {stopDetectorResources.map(stop => (
                <TableRowStyled key={stop.id}>
                  <TableCellStyled>
                    <Typography color="textSecondary">
                      <PanTool color="secondary" />
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled>{stop.strDataHoraEvento}</TableCellStyled>
                  <TableCellStyled>{stop.latitude}</TableCellStyled>
                  <TableCellStyled>{stop.longitude}</TableCellStyled>
                  <TableCellStyled>{stop.codigoMotivoParada}</TableCellStyled>
                  <TableCellStyled>
                    {stop.isTransmit ? 'SIM' : 'NÃO'}
                  </TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBodyStyled>
          </TableStyled>
        </CardContent>
      </Card>
    );
  }
  return null;
};

export default Paradas;
