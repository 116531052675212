import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import ocorrencia from './ocorrencia/sagas';
import usuario from './usuario/sagas';
import veiculo from './veiculo/sagas';

export default function* rootSaga() {
  const response: unknown = yield all([
    auth,
    user,
    ocorrencia,
    usuario,
    veiculo,
  ]);
  return response;
}
