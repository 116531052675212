import React, { ReactType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Default from '../layout/Default';
import Drawer from '../layout/Drawer';
import { store } from '../store';

interface IProp {
  path: string;
  exact?: boolean;
  isPrivate?: boolean;
  component: React.FC;
}

const RouteWrapper = ({ component, isPrivate = false, ...rest }: IProp) => {
  const Component: ReactType = component;
  const { signed } = store.getState().auth;
  const Template = signed ? Drawer : Default;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Template>
          <Component {...props} />
        </Template>
      )}
    />
  );
};

export default RouteWrapper;
