import React, { MouseEventHandler } from 'react';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import { PlusOneOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

interface ITitleGridProps {
  title: string;
  path?: string;
  onFilter?: MouseEventHandler<HTMLButtonElement>;
}

const TitleGrid: React.FC<ITitleGridProps> = ({ title, path, onFilter }) => {
  const history = useHistory();
  return (
    <>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h4" color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Grid container justify="flex-end" alignItems="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                type="button"
                onClick={() => history.push('/home')}
              >
                Voltar
              </Button>
            </Grid>
            {onFilter && (
              <Grid item>
                <Button color="secondary" type="button" onClick={onFilter}>
                  Filtrar
                </Button>
              </Grid>
            )}
            {path && (
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => history.push(path)}
                  type="button"
                  startIcon={<PlusOneOutlined />}
                >
                  Adicionar
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default TitleGrid;
