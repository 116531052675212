import { Card, CardContent, Container, Divider } from '@material-ui/core';

import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import { Grid, Button, Typography } from '@material-ui/core';

import { Form, Input } from '@yagolopes/unityform';

import { schema } from './schema';
import { TVeiculo } from '../Veiculo';
import { useDispatch } from 'react-redux';
import { cadastroVeiculoRequest } from '../../../store/modules/veiculo/actions';

export type TData = {
  nome: string;
  email: string;
  cpf: string;
};

interface IVeiculosCadastroProps {
  location?: {
    state: {
      veiculo: TVeiculo;
    };
  };
}

const VeiculosCadastro: React.FC<IVeiculosCadastroProps> = ({ location }) => {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { veiculo } = location?.state || {};

  const handleSubmit = async (data: TData) => {
    try {
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      dispatch(cadastroVeiculoRequest(data));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };
  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Form initialData={veiculo} ref={formRef} onSubmit={handleSubmit}>
                <Typography variant="h4" color="textSecondary">
                  Dados do veículo
                </Typography>
                <Divider />
                <Grid style={{ marginTop: 20 }} container spacing={2}>
                  <Input
                    title="Nome"
                    name="name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <Input
                    title="Placa"
                    name="plate"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <Input
                    title="Chassi"
                    name="chassis"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid
                  style={{ marginTop: 50 }}
                  container
                  spacing={2}
                  justify="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => history.push('/veiculos')}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VeiculosCadastro;
