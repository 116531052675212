import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Viagem, { TViagem } from './Viagem';
import api from '../../services/api';
import Filtro from './Filtro';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import ButtonOptions from './ButtonOptions';
import TitleGrid from '../../components/TitleGrid';

export type TOnLoad = {
  dateStart: string;
  dateEnd: string;
  status: boolean;
};

interface IViagensProps {
  location?: {
    state: {
      filtros?: TOnLoad;
    };
  };
}

const Viagens: React.FC<IViagensProps> = ({ location }) => {
  const initialFiltros = location?.state?.filtros;
  const [viagens, setViagem] = useState<TViagem[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState<TOnLoad | undefined>(initialFiltros);
  const [filtro, setFiltro] = useState();

  const onLoad = async ({ dateStart, dateEnd, status }: TOnLoad) => {
    try {
      setLoading(true);
      const {
        data: { content, totalPages },
      } = await api.get(
        `/api/triip?page=${
          page - 1
        }&size=10&dateStart=${dateStart}&dateEnded=${dateEnd}&status=${status}`,
      );
      setViagem(content);
      setTotalPages(totalPages);
      setFiltros({ dateStart, dateEnd, status });
      setLoading(false);
    } catch (error) {
      toast.error('Erro intervalo entre datas maior que 30 dias');
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const dataAtual = new Date();
    if (!filtros) {
      onLoad({
        dateStart: format(dataAtual, 'yyyy-MM-dd'),
        dateEnd: format(dataAtual, 'yyyy-MM-dd'),
        status: true,
      });
    } else {
      onLoad(filtros as TOnLoad);
    }
  }, [page]);

  return (
    <Container>
      <Filtro
        onSubmit={onLoad}
        initialFiltros={initialFiltros}
        setFiltro={setFiltro}
      />
      <Card>
        <CardContent>
          <TitleGrid title="Viagens" />
          <ButtonOptions filtro={filtro} />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled>
                        <b>Data e hora</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Perfixo</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Ida/Volta</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Atualizado em</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Passagens</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Canceladas</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Embarques</b>
                      </TableCellStyled>
                      <TableCellStyled>
                        <b>Status</b>
                      </TableCellStyled>
                      <TableCellStyled />
                      <TableCellStyled />
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {loading ? (
                      <CircularProgress disableShrink />
                    ) : (
                      viagens.map(viagem => (
                        <Viagem
                          key={viagem.id}
                          viagem={viagem}
                          filtros={filtros}
                        />
                      ))
                    )}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                onChange={handleChange}
                count={totalPages}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Viagens;
