import React, { useRef } from 'react';
import { CardContent, Button, Grid } from '@material-ui/core';
import { Form, Autocomplete, DatePicker } from '@yagolopes/unityform';
import { Container } from './styles';
import { schema } from './schema';
import { format, subMonths } from 'date-fns';
import { stringToDate } from '../../../util';
import { TOnLoad } from '..';

interface IFiltro {
  onSubmit: Function;
  initialFiltros?: TOnLoad;
  setFiltro: Function;
}

export type TData = {
  status: string;
  dateStart: string;
  dateEnd: string;
  setFiltro: Function;
};

const Filtro: React.FC<IFiltro> = ({ onSubmit, initialFiltros, setFiltro }) => {
  const formRef = useRef(null);

  const handleSubmit = async (data: TData) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const { value } = JSON.parse(data.status);
      const dateStartObj = stringToDate(
        data.dateStart as string,
        'dd/MM/yyyy',
        '/',
      );
      const dateStart = format(dateStartObj, 'yyyy-MM-dd');
      const dateEndObj = stringToDate(
        data.dateEnd as string,
        'dd/MM/yyyy',
        '/',
      );
      const dateEnd = format(dateEndObj, 'yyyy-MM-dd');
      const payload = { dateEnd, dateStart, status: Boolean(value === 'true') };
      setFiltro(payload);
      onSubmit(payload);
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };

  const dateNow = new Date();
  const initialFiltro = {
    dateEnd: dateNow,
    dateStart: subMonths(dateNow, 1),
  };

  return (
    <Container>
      <CardContent>
        <Form
          initialData={initialFiltros || initialFiltro}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <Grid style={{ marginTop: 20 }} container spacing={2}>
            <Grid xs={4}>
              <Autocomplete
                title="Status"
                name="status"
                variant="outlined"
                options={[
                  { label: 'Finalizado', value: 'true' },
                  { label: 'Em andamento', value: 'false' },
                ]}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={3}>
              <DatePicker
                title="Data da início"
                name="dateStart"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={3}>
              <DatePicker
                title="Data da Fim"
                name="dateEnd"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>

            <Grid xs={2}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                style={{ height: '100%' }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Consultar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Container>
  );
};

export default Filtro;
