import { Grid, Button as ButtonUI  } from '@material-ui/core';
import styled from 'styled-components';
import logo from '../../assets/logo-branca.png';
export const Banner = styled(Grid)`
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-color: ${({theme})=>theme.palette.primary.main };
    background-position: center;
`;

export const Wrapper = styled.div`
    margin: ${ ({theme}) => theme.spacing(25, 3)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form{
        width: 100%;
        max-width: 500px;
        min-width: 200px;
    }
`;

export const Button = styled(ButtonUI)`
  font-size: 16px;
  height: 50px;
  cursor: pointer;
`;
