import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../components/Table';
import Motorista, { TMotorista } from './Motorista';
import api from '../../services/api';
import { toast } from 'react-toastify';
import TitleGrid from '../../components/TitleGrid';

interface IState {
  content: TMotorista[];
  totalPages: number;
}

const InitialState = {
  content: [],
  totalPages: 1,
};

const Motoristas: React.FC = () => {
  const [state, setState] = useState<IState>(InitialState);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/api/driver?page=${page - 1}&size=10`);
      setState(data);
      setLoading(false);
    } catch (error) {
      toast.error('Erro intervalo entre datas maior que 30 dias');
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    onLoad();
  }, [page]);

  return (
    <Container>
      <Card>
        <CardContent>
          <TitleGrid title="Motoristas" path="/motoristas/cadastro" />
          <div style={{ maxWidth: '100%', overflow: 'auto' }}>
            <Grid container>
              <Grid item>
                <TableStyled>
                  <TableHeadStyled>
                    <TableRowStyled>
                      <TableCellStyled>Nome</TableCellStyled>
                      <TableCellStyled>Documento</TableCellStyled>
                      <TableCellStyled colSpan={2}>Endereço</TableCellStyled>
                      <TableCellStyled />
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBodyStyled>
                    {loading ? (
                      <CircularProgress disableShrink />
                    ) : (
                      state.content.map(motorista => (
                        <Motorista key={motorista.id} motorista={motorista} />
                      ))
                    )}
                  </TableBodyStyled>
                </TableStyled>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ marginBottom: 15 }}
                page={page}
                onChange={handleChange}
                count={state.totalPages}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Container>
  );
};

export default Motoristas;
