import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#ffffff',
      main: '#00B761',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#2274A5',
      dark: '#000000',
      contrastText: '#fff',
    },
    error: {
      light: '#ffffff',
      main: '#F75C03',
      dark: '#000000',
      contrastText: '#fff',
    },
    divider: '#dddd',
  },
});

export default theme;
