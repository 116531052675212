import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { TOnLoad } from '..';
import api from '../../../services/api';

const options = [
  { label: 'Velocidade Tempo e Localização', value: '1' },
  { label: 'Detector de Paradas', value: '2' },
  { label: 'Jornada do Motorista', value: '3' },
  { label: 'Venda de Passagens', value: '5' },
];

interface IButtonOptions {
  filtro?: TOnLoad;
}

const ButtonOptions: React.FC<IButtonOptions> = ({ filtro }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { dateStart, dateEnd, status } = filtro || {};

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = async (value: string) => {
    try {
      handleClose();
      const { data } = await api.get(
        `/api/reports/${value}?dateStart=${dateStart}&dateEnded=${dateEnd}&status=${status}`,
      );
      handleCreatDocument(data.csv);
    } catch (error) {
      toast.error('Erro arquivo não encontrado!');
    }
  };

  const handleCreatDocument = (csv: string) => {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
  };

  return (
    <div>
      <Tooltip title="Fazer download do CSV" placement="top-end">
        <IconButton
          color="primary"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(opt => (
          <MenuItem key={opt.value} onClick={() => handleSelect(opt.value)}>
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonOptions;
