import styled from 'styled-components';

import { TableHead } from '@material-ui/core';

const TableHeadStyled = styled(TableHead)`
  thead {
    tr {
      th {
        color: rgba(0, 0, 0, 0.3);
        font-weight: normal;
        text-align: center;
      }
    }
  }
`;

export default TableHeadStyled;
