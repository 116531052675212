import { Card, CardContent, Container } from '@material-ui/core';

import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import { Grid, Button, Typography } from '@material-ui/core';

import { Form, Input } from '@yagolopes/unityform';

import { schema } from './schema';
import { TUsuario } from '../Usuario';
import { useDispatch } from 'react-redux';
import { cadastroUsuarioRequest } from '../../../store/modules/usuario/actions';

export type TData = {
  name: string;
  email: string;
  password: string;
};

interface IUsuarioCadastroProps {
  location?: {
    state: {
      usuario: TUsuario;
    };
  };
}

const UsuarioCadastro: React.FC<IUsuarioCadastroProps> = ({ location }) => {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { usuario } = location?.state || {};

  const handleSubmit = async (data: TData) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(cadastroUsuarioRequest(data));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          console.log(error.path);
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };
  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Form initialData={usuario} ref={formRef} onSubmit={handleSubmit}>
                <Typography variant="h4" color="textSecondary">
                  Cadastro de usuários
                </Typography>
                <Grid
                  style={{ marginTop: 20, marginBottom: 20 }}
                  container
                  spacing={2}
                >
                  <Input
                    title="Nome"
                    variant="outlined"
                    name="name"
                    fullWidth
                    required
                  />
                  <Input
                    title="Email"
                    variant="outlined"
                    name="email"
                    fullWidth
                    required
                  />
                  <Input
                    title="Senha atual"
                    name="password"
                    required
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                </Grid>
                <Grid
                  style={{ marginTop: 50 }}
                  container
                  spacing={2}
                  justify="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => history.push('/usuarios')}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UsuarioCadastro;
