import styled from 'styled-components';

import { TableBody } from '@material-ui/core';

const TableBodyStyled = styled(TableBody)`
  tbody {
    tr {
      margin-top: 15px;
    }

    td {
      padding: 20px;
      background: white;
      text-align: center;
    }
  }
`;

export default TableBodyStyled;
