import * as Yup from 'yup';

export const schema = Yup.object().shape({
  nomeReclamante: Yup.string().required('Esse campo é obrigatório'),
  telefoneContatoReclamante: Yup.string().required('Esse campo é obrigatório'),
  codigoTipoOcorrencia: Yup.string().required('Esse campo é obrigatório'),
  dataOcorrencia: Yup.string().required('Esse campo é obrigatório'),
  horaOcorrencia: Yup.string().required('Esse campo é obrigatório'),
  descricaoOcorrencia: Yup.string().required('Esse campo é obrigatório'),
});
