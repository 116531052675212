import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import { updateVeiculoSuccess, cadastroVeiculoSuccess } from './actions';
import { TData } from '../../../pages/Veiculos/Cadastro';

interface ICadastroVeiculoProps {
  payload: {
    data: TData;
  };
}

export function* cadastroVeiculo({ payload }: ICadastroVeiculoProps) {
  try {
    yield call(api.post, '/api/vehicle', payload.data);
    yield put(cadastroVeiculoSuccess());
    toast.success('Veículo cadastrado com sucesso!');
    history.push('/veiculos');
  } catch (err) {
    toast.error('Erro ao cadastrar veículo, tente novamente mais tarde!');
  }
}

export function* updateVeiculo({ payload }: any) {
  try {
    yield call(api.put, '/users', payload.data);
    yield put(updateVeiculoSuccess());
    toast.success('Veículo atualizado com sucesso!');
  } catch (err) {
    toast.error('Erro ao atualizar o veículo, tente novamente mais tarde!');
  }
}

export default all([
  takeLatest<string, any>('@veiculo/UPDATE_VEICULO_REQUEST', updateVeiculo),
  takeLatest<string, any>('@veiculo/CADASTRO_VEICULO_REQUEST', cadastroVeiculo),
]);
