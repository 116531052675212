import { Card, CardContent, Container, Divider } from '@material-ui/core';

import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import { Grid, Button, Typography } from '@material-ui/core';

import { DatePicker, Form, Input, Mask } from '@yagolopes/unityform';

import { schema } from './schema';
import { TOcorrencia } from '../Ocorrencia';
import { cadastroOcorrenciaRequest } from '../../../store/modules/ocorrencia/actions';
import { useDispatch } from 'react-redux';
import { stringToDate } from '../../../util';
import { format } from 'date-fns';

export type TData = {
  numeroBilheteEmbarque: number;
  nomeReclamante: string;
  documentoIdentificacaoReclamante: string;
  enderecoReclamante: string;
  telefoneContatoReclamante: string;
  emailReclamante: string;
  codigoTipoOcorrencia: number;
  dataOcorrencia: string | Date;
  horaOcorrencia: string;
  descricaoOcorrencia: string;
};

interface IOcorrenciaCadastroProps {
  location?: {
    state: {
      ocorrencia: TOcorrencia;
    };
  };
}

const OcorrenciaCadastro: React.FC<IOcorrenciaCadastroProps> = ({
  location,
}) => {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { ocorrencia } = location?.state || {};

  const handleSubmit = async (data: TData) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const dateObj = stringToDate(
        data.dataOcorrencia as string,
        'dd/MM/yyyy',
        '/',
      );
      const dateApi = format(dateObj, 'yyyy-MM-dd');
      dispatch(cadastroOcorrenciaRequest({ ...data, dataOcorrencia: dateApi }));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };
  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={8}>
              <Form
                initialData={ocorrencia}
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <Typography variant="h4" color="textSecondary">
                  Dados da ocorrência
                </Typography>
                <Divider />
                <Grid style={{ marginTop: 20 }} container spacing={2}>
                  <Grid xs={4}>
                    <Input
                      title="Numero do bilhete de embarque "
                      name="numeroBilheteEmbarque"
                      type="number"
                      variant="outlined"
                      disabled={!!ocorrencia}
                      autoFocus
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={8}>
                    <Input
                      title="Nome do reclamante"
                      name="nomeReclamante"
                      variant="outlined"
                      disabled={!!ocorrencia}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Input
                      title="Documento de Identificacao"
                      disabled={!!ocorrencia}
                      name="documentoIdentificacaoReclamante"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={8}>
                    <Input
                      title="Endereço"
                      disabled={!!ocorrencia}
                      name="enderecoReclamante"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Mask
                      title="Telefone"
                      name="telefoneContatoReclamante"
                      mask="(99) 9999-9999"
                      disabled={!!ocorrencia}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Input
                      title="E-mail"
                      disabled={!!ocorrencia}
                      name="emailReclamante"
                      variant="outlined"
                      fullWidth
                      type="email"
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Input
                      title="Código da ocorrência"
                      name="codigoTipoOcorrencia"
                      disabled={!!ocorrencia}
                      type="number"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={4}>
                    <DatePicker
                      title="Data da ocorrência"
                      name="dataOcorrencia"
                      disabled={!!ocorrencia}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Input
                      title="Hora da ocorrência"
                      name="horaOcorrencia"
                      disabled={!!ocorrencia}
                      type="time"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Input
                      title="Descrição da ocorrência"
                      name="descricaoOcorrencia"
                      disabled={!!ocorrencia}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>

                <Grid
                  style={{ marginTop: 50 }}
                  container
                  spacing={2}
                  justify="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => history.push('/ocorrencias')}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  {!ocorrencia && (
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        Próximo
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default OcorrenciaCadastro;
