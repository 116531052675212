import React, { useRef } from 'react';
import { Form, Input } from '@yagolopes/unityform';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Paper } from '@material-ui/core';
import { Banner, Wrapper, Button } from './styles';
import {
  SignInRequestProps,
  signInRequest,
} from '../../store/modules/auth/actions';
import { AuthStateProps } from '../../store/modules/auth/reducer';
import schema from './schema';

const SignIn: React.FC = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector<{ auth: AuthStateProps }>(
    state => state.auth.loading,
  );

  const handleSubmit = async (data: SignInRequestProps) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      dispatch(signInRequest(data));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };

  return (
    <Grid container component="main" style={{ height: '100vh' }}>
      <Banner item xs={false} sm={4} md={7} />
      <Grid item xs={12} sm={4} md={5} component={Paper}>
        <Wrapper>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              required
              fullWidth
              name="username"
              autoComplete="username"
              variant="outlined"
              autoFocus
              title="Usuário"
            />
            <Input
              required
              fullWidth
              name="password"
              variant="outlined"
              title="Senha"
              type="password"
            />
            <Box
              mx={1}
              p={1}
              width="100%"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {loading ? 'Carregando...' : 'Acessar'}
              </Button>
            </Box>
          </Form>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default SignIn;
