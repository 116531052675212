import { TData } from '../../../pages/Motoristas/Cadastro';
export function cadastroMotoristaRequest(data: TData) {
  return {
    type: '@motoristas/CADASTRO_MOTORISTA_REQUEST',
    payload: { data },
  };
}

export function cadastroMotoristaSuccess() {
  return {
    type: '@motoristas/CADASTRO_MOTORISTA_SUCCESS',
  };
}

export function desativarMotoristaRequest(id: number) {
  return {
    type: '@motoristas/DESATIVAR_MOTORISTA_REQUEST',
    payload: { id },
  };
}

export function desativarMotoristaSuccess() {
  return {
    type: '@motoristas/DESATIVAR_MOTORISTA_SUCCESS',
  };
}

export function ativarMotoristaRequest(id: number) {
  return {
    type: '@motoristas/ATIVAR_MOTORISTA_REQUEST',
    payload: { id },
  };
}

export function ativarMotoristaSuccess() {
  return {
    type: '@motoristas/ATIVAR_MOTORISTA_SUCCESS',
  };
}
