import produce from 'immer';
import { Action } from 'redux';

interface IAction extends Action {
  payload: any;
}

const INITIAL_STATE = {
  loading: false,
};

export default function veiculo(state = INITIAL_STATE, action: IAction) {
  return produce(state, draft => {
    switch (action.type) {
      case '@veiculo/CADASTRO_VEICULO_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@veiculo/CADASTRO_VEICULO_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@veiculo/UPDATE_VEICULO_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@veiculo/UPDATE_VEICULO_SUCCESS': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
