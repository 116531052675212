import styled from 'styled-components';

import { Table } from '@material-ui/core';

const TableStyled = styled(Table)`
  color: black;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
  display: table;

  ${({ theme }) => theme.breakpoints.up('md')} {
    table-layout: fixed;
  }
`;

export default TableStyled;
