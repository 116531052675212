import React, { useRef } from 'react';
import { CardContent, Button, Grid } from '@material-ui/core';
import { Form, Autocomplete, DatePicker } from '@yagolopes/unityform';
import { Container } from './styles';
import { schema } from './schema';
import { format } from 'date-fns';
import { stringToDate } from '../../../util';
import { subMonths } from 'date-fns/esm';
const options = [
  { label: 'Viagens', value: '0' },
  { label: 'Velocidade Tempo e Localização', value: '1' },
  { label: 'Detector de Paradas', value: '2' },
  { label: 'Jornada do Motorista', value: '3' },
  { label: 'Registro de Ocorrências', value: '4' },
  { label: 'Venda de Passagens', value: '5' },
  { label: 'Controle de Embarque', value: '6' },
  { label: 'Cancelamentos de Passagens', value: '7' },
];

interface IFiltro {
  onSubmit: Function;
}

export type TData = {
  tipo: string;
  dateStart: string;
  dateEnd: string;
};

const Filtro: React.FC<IFiltro> = ({ onSubmit }) => {
  const formRef = useRef(null);
  const handleSubmit = async (data: TData) => {
    try {
      // Remove all previous errors
      const { setErrors } = formRef.current as any;
      setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const { value } = JSON.parse(data.tipo);
      const dateStartObj = stringToDate(
        data.dateStart as string,
        'dd/MM/yyyy',
        '/',
      );
      const dateStart = format(dateStartObj, 'yyyy-MM-dd');
      const dateEndObj = stringToDate(
        data.dateEnd as string,
        'dd/MM/yyyy',
        '/',
      );
      const dateEnd = format(dateEndObj, 'yyyy-MM-dd');

      onSubmit({ dateEnd, dateStart, eventId: value });
      // dispatch(cadastroUsuarioRequest(data));
    } catch (err) {
      const validationErrors: any = {};
      if (err) {
        err.inner.forEach((error: { path: string; message: string }) => {
          validationErrors[error.path] = error.message;
        });

        const { setErrors } = formRef.current as any;
        setErrors(validationErrors);
      }
    }
  };

  const dateNow = new Date();
  const initialData = {
    dateEnd: dateNow,
    dateStart: subMonths(dateNow, 1),
  };

  return (
    <Container>
      <CardContent>
        <Form ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
          <Grid style={{ marginTop: 20 }} container spacing={2}>
            <Grid xs={4}>
              <Autocomplete
                title="Tipo de evento"
                name="tipo"
                variant="outlined"
                options={options}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={3}>
              <DatePicker
                title="Data da início"
                name="dateStart"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={3}>
              <DatePicker
                title="Data da Fim"
                name="dateEnd"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={2}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                style={{ height: '100%' }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Consultar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Container>
  );
};

export default Filtro;
