import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import {
  TableBodyStyled,
  TableCellStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
} from '../../../../../../components/Table';
import { TTicket } from '../types';

interface IPassagensProps {
  tickets?: TTicket[];
}

const Passagens: React.FC<IPassagensProps> = ({ tickets = [] }) => {
  const handleGetSituation = (situation: string) => {
    switch (situation) {
      case 'V':
        return 'Vendido';
      case 'C':
        return 'Cancelado';

      default:
        return 'Embarcado';
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Passagens</Typography>
        <div style={{ overflowY: 'auto' }}>
          <TableStyled>
            <TableHeadStyled>
              <TableRowStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>Localizador</TableCellStyled>
                <TableCellStyled>Nome Passageiro</TableCellStyled>
                <TableCellStyled>Origem</TableCellStyled>
                <TableCellStyled>Destino</TableCellStyled>
                <TableCellStyled>Poltrona</TableCellStyled>
                <TableCellStyled>Valor total</TableCellStyled>
                <TableCellStyled>Sit</TableCellStyled>
                <TableCellStyled>Ext. ID</TableCellStyled>
                <TableCellStyled>Transmitido</TableCellStyled>
              </TableRowStyled>
            </TableHeadStyled>
            <TableBodyStyled>
              {tickets.map(ticket => (
                <TableRowStyled key={ticket.id}>
                  <TableCellStyled>
                    <Typography color="textSecondary">
                      <ConfirmationNumber color="secondary" />
                    </Typography>
                  </TableCellStyled>
                  <TableCellStyled>
                    {ticket.numeroBilheteEmbarque}
                  </TableCellStyled>
                  <TableCellStyled>{ticket.nomePassageiro}</TableCellStyled>
                  <TableCellStyled>
                    {ticket.idPontoOrigemViagem}
                  </TableCellStyled>
                  <TableCellStyled>
                    {ticket.idPontoDestinoViagem}
                  </TableCellStyled>
                  <TableCellStyled>{ticket.numeroPoltrona}</TableCellStyled>
                  <TableCellStyled>{ticket.valorTotal}</TableCellStyled>
                  <TableCellStyled>
                    {handleGetSituation(ticket.situation)}
                  </TableCellStyled>
                  <TableCellStyled>{ticket.externalId}</TableCellStyled>
                  <TableCellStyled>
                    {ticket.isTransmit ? 'SIM' : 'NÃO'}
                  </TableCellStyled>
                </TableRowStyled>
              ))}
            </TableBodyStyled>
          </TableStyled>
        </div>
      </CardContent>
    </Card>
  );
};

export default Passagens;
